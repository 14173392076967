<template>
  <div>
    <Hero />
    <Arguments />
    <Features />
    <Creators />
    <Playlists />
    <Footer />
  </div>
</template>

<script>
import Hero from '@/components/Hero'
import Arguments from '@/components/Arguments'
import Features from '@/components/Features'
import Creators from '@/components/Creators'
import Footer from '@/components/Footer'
import Playlists from '@/components/Playlists'

export default {
  head() {
    return {
      title: {
        inner: 'MindBites App'
      },
      meta: [
        {
          name: 'description',
          content: `Search our topic-focused playlists, tailored to your interests.`,
          id: 'desc'
        },
        {
          name: 'keywords',
          content: `podcast playlist app`,
          id: 'keywords'
        }
      ]
    }
  },
  components: { Playlists, Hero, Arguments, Features, Creators, Footer }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
