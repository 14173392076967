<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

body {
  margin: 0;
  font-family: $fontSF;
}

* {
  box-sizing: border-box;
}
</style>
