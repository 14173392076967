<template>
  <section class="playlist-wrapper">
    <div class="playlist-page">
      <!-- Top Section -->
      <div
        class="playlist-top"
        :style="{ backgroundImage: `url(${playlist.imgURL})` }"
      >
        <div class="top-content">
          <!-- Logo -->
          <svg
            class="mindbites-logo"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 188 35"
          >
            <path
              fill="#4FA595"
              d="M48.93 6.247h4.129l5.119 15.817 5.176-15.817h4.072l3.727 22.661h-4.072l-2.416-14.333-4.716 14.333h-3.646l-4.659-14.333-2.439 14.333H45.11l3.82-22.66ZM73.063 12.543h4.094V28.91h-4.094V12.542ZM84.347 14.244a7.27 7.27 0 0 1 4.831-2.093 5.384 5.384 0 0 1 5.613 5.544v11.181h-4.095v-7.73c0-3.244-.287-5.303-2.783-5.303a3.233 3.233 0 0 0-2.772 1.6 8.052 8.052 0 0 0-.794 4.508v6.96H80.24V12.54h4.107v1.703ZM110.226 27.185a6.828 6.828 0 0 1-5.05 2.163c-4.164 0-7.799-3.578-7.799-8.604 0-5.027 3.451-8.628 7.707-8.628a6.993 6.993 0 0 1 5.142 2.22V6.192h4.106v22.719h-4.106v-1.726Zm-8.754-6.476a4.485 4.485 0 0 0 2.635 4.542 4.476 4.476 0 0 0 6.268-4.542 4.478 4.478 0 0 0-4.497-4.808c-2.681-.023-4.406 2.128-4.406 4.808Z"
            />
            <path
              fill="#EFF0F3"
              d="M117.668 28.91V6.192h4.037c2.301 0 5.752-.184 8.053 1.277a5.639 5.639 0 0 1 2.772 4.797 5.569 5.569 0 0 1-2.301 4.475c2.588 1.23 3.877 3.013 3.877 5.75a5.975 5.975 0 0 1-1.91 4.601 6.9 6.9 0 0 1-5.418 1.818h-9.11Zm4.222-18.669v4.889h3.45c1.967 0 2.991-.863 2.991-2.404 0-1.542-.955-2.485-2.841-2.485h-3.6Zm0 8.961v5.751h3.957c2.645 0 4.06-.736 4.06-2.622 0-1.887-1.472-3.083-4.429-3.083l-3.588-.046ZM136.604 12.543h4.095V28.91h-4.095V12.542ZM143.665 6.192h4.095v6.35h2.439v3.52h-2.439V28.91h-4.095V6.192ZM167.145 25.747c-1.748 2.497-4.06 3.612-7.511 3.612a8.296 8.296 0 0 1-8.57-8.638 8.364 8.364 0 0 1 8.501-8.605c5.176 0 8.696 3.693 8.696 8.88v.92h-13.228a4.379 4.379 0 0 0 4.601 3.785 5.268 5.268 0 0 0 4.06-1.599l3.451 1.645Zm-3.014-7.05a4.764 4.764 0 0 0-7.168-2.24 4.764 4.764 0 0 0-1.746 2.24h8.914ZM174.175 21.825c-2.623-1.323-3.946-2.934-3.946-4.993 0-2.553 2.163-4.716 5.303-4.716a6.652 6.652 0 0 1 5.395 2.715l-2.531 2.553a4.208 4.208 0 0 0-2.829-1.507c-.84 0-1.484.334-1.484.99 0 .655.586.886 1.484 1.346l1.507.77c2.714 1.392 4.164 2.807 4.164 5.246 0 2.921-2.301 5.118-5.89 5.118a6.904 6.904 0 0 1-5.751-2.806l2.519-2.773a5.164 5.164 0 0 0 3.451 1.91c1.012 0 1.817-.587 1.817-1.323s-.679-1.242-1.817-1.817l-1.392-.713ZM188 26.898a2.449 2.449 0 0 1-.714 1.724 2.443 2.443 0 0 1-1.724.715 2.529 2.529 0 0 1-2.416-2.611 2.368 2.368 0 0 1 2.427-2.301A2.473 2.473 0 0 1 188 26.898Z"
            />
            <path
              fill="#4FA595"
              d="M34.329 16.438C33.923 7.6 26.783.425 17.984.018 8.131-.435 0 7.455 0 17.254c0 .77.052 1.539.154 2.302a2.08 2.08 0 0 0 1.531 1.73A17.236 17.236 0 0 1 13.76 32.958a2.073 2.073 0 0 0 1.773 1.468c.54.053 1.088.08 1.642.08 9.753 0 17.609-8.168 17.155-18.068ZM18.517 29.453h-.033a1.194 1.194 0 0 1-1.199-.843 17.319 17.319 0 0 0-11.38-11.314 1.2 1.2 0 0 1-.849-1.206v-.03a1.194 1.194 0 0 1 1.535-1.09c6.181 1.892 11.06 6.768 13 12.929a1.198 1.198 0 0 1-1.074 1.553Zm6.198-2.43-.024.022a1.192 1.192 0 0 1-1.931-.586c-1.96-7.294-7.83-13.01-15.239-14.68-.856-.192-1.205-1.22-.662-1.917l.016-.022a1.192 1.192 0 0 1 1.202-.42c8.26 1.872 14.81 8.244 17.007 16.375a1.201 1.201 0 0 1-.369 1.229Z"
            />
          </svg>
          <!-- Title -->
          <h1 class="title">{{ playlist.title }}</h1>
          <!-- Subtitle -->
          <p class="subtitle">Download app below to start listening now</p>
        </div>
      </div>
      <!-- Botton Section -->
      <div class="playlist-bottom">
        <!-- Rating -->
        <div class="rating">
          <div class="stars">
            <svg
              v-for="i in 5"
              :key="i"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 13 12"
            >
              <path
                fill="#F80"
                d="m6.5 0 2.002 3.735 3.998.849L9.74 7.74l.468 4.26L6.5 10.216 2.792 12l.468-4.26L.5 4.584l3.998-.849L6.5 0Z"
              />
            </svg>
          </div>

          <p class="number">{{ playlist.rating }}</p>
        </div>
        <!-- Description -->
        <p class="description">{{ playlist.popup.desc }}</p>
        <!-- Playlist Info -->
        <div class="playlist-details">
          <!-- Playlist Time -->
          <div class="timer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 36 36"
            >
              <g fill="#EFF0F3" opacity=".8">
                <path
                  d="m32.45 11.952 2.44-2.905a.668.668 0 0 0-.083-.94l-1.966-1.65a.666.666 0 0 0-.938.083l-2.27 2.705a14.288 14.288 0 0 0-6.497-2.625V4.22h2.899a.588.588 0 0 0 .588-.589V1.368a.588.588 0 0 0-.588-.588h-9.711a.588.588 0 0 0-.589.588v2.264c0 .326.264.59.589.59h2.899v2.387a14.326 14.326 0 0 0-6.508 2.621l-2.258-2.69a.666.666 0 0 0-.939-.083l-1.966 1.65a.666.666 0 0 0-.082.94l2.429 2.895c-.786.996-1.454 2.1-1.964 3.308a1.166 1.166 0 1 0 2.149.91 12.025 12.025 0 0 1 11.097-7.355c6.639 0 12.042 5.401 12.042 12.042 0 6.64-5.403 12.04-12.042 12.04-1.942 0-3.793-.446-5.506-1.327a1.167 1.167 0 0 0-1.068 2.074 14.411 14.411 0 0 0 6.574 1.587c7.926 0 14.375-6.448 14.375-14.374a14.304 14.304 0 0 0-3.105-8.895Z"
                />
                <path
                  d="M23.646 21.292c3.93-4.676 4.165-6.027 3.9-6.293-.267-.265-1.618-.032-6.293 3.9-2.225 1.87-1.525 2.943-1.038 3.43.488.488 1.56 1.188 3.43-1.037ZM14.18 19.544a1 1 0 0 0-1-1H1.446a1 1 0 1 0 0 2h11.735c.553 0 1-.447 1-1ZM13.18 23.6H6.385a1 1 0 0 0 0 2h6.796a1 1 0 1 0 0-2ZM13.18 28.656H3.385a1 1 0 1 0 0 2h9.797a1 1 0 1 0 0-2Z"
                />
              </g>
            </svg>
            <p class="duration">{{ playlist.popup.duration }}</p>
          </div>
          <!-- Playlist Bites -->
          <div class="bites">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 43 39"
            >
              <g fill="#EFF0F3" opacity=".8">
                <path
                  d="M6.879 5.587c1.625 0 2.94 1.192 2.94 2.666s-1.315 2.666-2.94 2.666-2.94-1.192-2.94-2.666c0-1.47 1.319-2.666 2.94-2.666Z"
                />
                <path
                  d="M6.879 5.778c1.125 0 2.175.655 2.561 1.618.395.987.059 2.107-.831 2.777-.874.655-2.15.735-3.124.213-.966-.522-1.5-1.565-1.294-2.57.244-1.178 1.382-2.027 2.688-2.038.268-.004.268-.385 0-.381-1.323.011-2.516.765-2.97 1.897-.444 1.112-.05 2.414.962 3.16 1.017.755 2.478.869 3.612.256 1.146-.617 1.738-1.847 1.482-3.028C9.68 6.368 8.353 5.393 6.88 5.393c-.27.004-.27.385 0 .385ZM14.425 9.205h20.87c.961 0 1.927.02 2.889 0h.042c.55 0 1.075-.438 1.05-.952-.026-.514-.462-.952-1.05-.952h-20.87c-.962 0-1.928-.02-2.89 0h-.041c-.55 0-1.075.438-1.05.952.025.518.458.952 1.05.952ZM6.879 16.834c1.625 0 2.94 1.192 2.94 2.666s-1.315 2.666-2.94 2.666-2.94-1.192-2.94-2.666 1.319-2.666 2.94-2.666Z"
                />
                <path
                  d="M6.879 17.024c1.125 0 2.175.655 2.561 1.619.395.986.059 2.106-.831 2.776-.874.655-2.15.735-3.124.214-.966-.522-1.5-1.566-1.294-2.571.244-1.177 1.382-2.026 2.688-2.038.268-.004.268-.384 0-.38-1.323.01-2.516.765-2.97 1.896-.444 1.112-.05 2.415.962 3.161 1.017.754 2.478.869 3.612.255 1.146-.616 1.738-1.847 1.482-3.027-.285-1.314-1.612-2.29-3.086-2.29-.27.005-.27.385 0 .385ZM14.425 20.452h20.87c.961 0 1.927.02 2.889 0h.042c.55 0 1.075-.438 1.05-.952-.026-.514-.462-.952-1.05-.952h-20.87c-.962 0-1.928-.019-2.89 0h-.041c-.55 0-1.075.438-1.05.952.025.514.458.952 1.05.952ZM6.879 28.081c1.625 0 2.94 1.192 2.94 2.666s-1.315 2.666-2.94 2.666-2.94-1.192-2.94-2.666 1.319-2.666 2.94-2.666Z"
                />
                <path
                  d="M6.879 28.271c1.125 0 2.175.655 2.561 1.619.395.986.059 2.106-.831 2.776-.874.656-2.15.736-3.124.214-.966-.522-1.5-1.566-1.294-2.571.244-1.177 1.382-2.026 2.688-2.038.268-.003.268-.384 0-.38-1.323.011-2.516.765-2.97 1.896-.444 1.112-.05 2.415.962 3.161 1.017.754 2.478.869 3.612.255 1.146-.617 1.738-1.847 1.482-3.027-.285-1.314-1.612-2.29-3.086-2.29-.27.005-.27.385 0 .385ZM14.425 31.699h20.87c.961 0 1.927.019 2.889 0h.042c.55 0 1.075-.438 1.05-.952-.026-.514-.462-.952-1.05-.952h-20.87c-.962 0-1.928-.02-2.89 0h-.041c-.55 0-1.075.438-1.05.952.025.514.458.952 1.05.952Z"
                />
              </g>
            </svg>
            <p class="clips">{{ playlist.popup.clips }} clips</p>
          </div>
        </div>
        <!-- Button -->
        <a
          :href="playlist.landingDeepLink"
          class="download-button"
          target="_blank"
        >
          Download for free
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import PlaylistsDB from '@/firebase/playlists-db'
import { seo } from '@/seo.json'

export default {
  name: 'PlaylistV2',
  metaInfo() {
    return {
      title: this.seoData.title,
      titleTemplate: `Mindbites | %s`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.seoData.desc
        },
        { vmid: 'og:title', property: 'og:title', content: this.seoData.title },
        { vmid: 'og:image', property: 'og:image', content: this.seoData.image }
      ]
    }
  },
  data() {
    return {
      playlist: null
    }
  },
  computed: {
    seoData() {
      return seo.find(el => this.$route.path.includes(el.slug))
    }
  },
  async mounted() {
    const playlistSlug = this.$route.params.playlist || ''
    const playlistDB = new PlaylistsDB()

    this.playlist = await playlistDB.read(playlistSlug)

    if (!this.playlist) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.playlist-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $darkBlue;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.playlist-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 475px;

  .playlist-top {
    position: relative;
    flex: 1;
    display: flex;
    padding: 50px 20px 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        0deg,
        rgba($darkBlue, 0.8) 0%,
        rgba($darkBlue, 0) 100%
      );
    }

    @media (max-width: 360px) {
      padding: 60px 20px 0;
    }

    .top-content {
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .mindbites-logo {
      height: 34px;
      margin-bottom: 12px;
    }

    .title {
      font-family: $fontMerri;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 133%;
      display: flex;
      align-items: flex-end;
      color: #eff0f3;
      margin: 0 0 5px;
    }

    .subtitle {
      font-family: $fontSF;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      text-alig: center;
      color: #eff0f3;
      margin: 0;
    }
  }

  .playlist-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: $darkBlue;

    .rating {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .stars {
        display: flex;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 4px;

          &:last-of-type {
            margin-right: 8px;
          }
        }
      }

      .number {
        margin: 0;
        font-family: $fontSF;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 100%;
        color: #eff0f3;
      }
    }

    .description {
      font-family: $fontSF;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 155%;
      color: #eff0f3;
      margin: 0 0 30px;
    }

    .playlist-details {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      .timer {
        display: flex;
        align-items: center;
        margin-right: 36px;

        svg {
          width: 36px;
          height: 36px;
          margin-right: 17px;
        }

        .duration {
          font-family: $fontSF;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
          color: #eff0f3;
        }
      }

      .bites {
        display: flex;
        align-items: center;

        svg {
          width: 43px;
          height: 39px;
          margin-right: 12px;
        }

        .clips {
          font-family: $fontSF;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
          color: #eff0f3;
        }
      }
    }

    .download-button {
      font-family: $fontSF;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
      text-decoration: none;
      padding: 16px;
      text-align: center;
      min-width: 200px;
      background: #4fa595;
      box-shadow: 0 3px 6px rgba(110, 116, 134, 0.24);
      border-radius: 4px;

      @media (max-width: 1200px) {
        margin-bottom: 25px;
      }
    }
  }
}
</style>
