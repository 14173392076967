<template>
  <section class="playlists">
    <h3 class="playlists-heading">Explore topics, mix clips, listen to your playlists</h3>
    <section class="middle-section">
      <section class="arrow-container" @click.prevent="previous()">
        <div class="arrow">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 14"><path d="M74.81 8H2.035V6H74.81v2z" fill="#3C476A"/><path d="M8.395.18c.309 0 .613.145.813.414a.995.995 0 01-.223 1.395L2.614 6.606c-.164.117-.258.254-.258.367 0 .117.094.25.254.371l6.379 4.668a1.003 1.003 0 01-1.184 1.617L1.426 8.961C.743 8.461.352 7.735.352 6.973s.395-1.484 1.082-1.984L7.805.37c.18-.129.387-.191.59-.191z" fill="#3C476A"/></svg>
        </div>
      </section>
      <p class="subtitle">Our <span>top</span> playlists</p>
      <section class="arrow-container" @click.prevent="next()">
        <div class="arrow">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 14"><path d="M.191 6h72.773v2H.191V6z" fill="#3C476A"/><path d="M66.605 13.82a1.01 1.01 0 01-.813-.414.995.995 0 01.223-1.395l6.371-4.617c.164-.117.258-.254.258-.367 0-.117-.094-.25-.254-.371l-6.379-4.668A1.003 1.003 0 0167.194.37l6.38 4.668c.683.5 1.074 1.226 1.074 1.988s-.395 1.484-1.082 1.984l-6.371 4.618c-.18.129-.387.191-.59.191z" fill="#3C476A"/></svg>
        </div>
      </section>
    </section>
    <Flickity ref="flickity" :options="flickityOptions" class="playlists-list">
      <a v-for="(playlist, index) in playlist" :key="index" href="https://apps.apple.com/ca/app/mindbites-player/id1506807825" target="_blank" class="playlist-item carousel-cell">
        <p class="playlist-title">{{ playlist.name }}</p>
        <img :src="playlist.image" :alt="playlist.name" class="playlist-image">
      </a>
    </Flickity>
  </section>
</template>

<script>
import json from '@/data.json'
import Flickity from 'vue-flickity'

export default {
  components: {
    Flickity
  },
  data() {
    return {
      playlist: json.playlists,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      }
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next()
    },
    previous() {
      this.$refs.flickity.previous()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.playlists {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 120px;

  @media (max-width: 850px) {
    padding: 64px 0;
  }

  .playlists-heading {
    font-family: $fontMerri;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    color: #3C476A;
    max-width: 550px;
    margin: 0 0 50px;
    padding: 0 24px;

    @media (max-width: 850px) {
      font-size: 26px;
      line-height: 160%;
      max-width: 330px;
    }
  }

  .middle-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 850px) {
      flex-wrap: wrap;
    }

    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 133%;
      color: #3C476A;
      text-align: center;
      margin: 0;

      @media (max-width: 850px) {
        width: 100%;
        order: 1;
        margin-bottom: 32px;
      }

      span {
        font-weight: 700;
        color: #75B27D;
      }
    }

    .arrow-container {
      padding: 0 56px;
      display: flex;

      @media (max-width: 850px) {
        width: 50%;
        order: 2;

        &:last-child {
          order: 3;
          justify-content: flex-end;
        }
      }

      .arrow {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 75px;
        }
      }
    }
  }

  .playlists-list {
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 56px;
      background: linear-gradient(267.33deg, rgba(255, 255, 255, 0.468) -10.58%, rgba(255, 255, 255, 0.9) 103.02%, rgba(255, 255, 255, 0.9) 131.05%);
      z-index: 99;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 56px;
      background: linear-gradient(267.33deg, rgba(255, 255, 255, 0.468) -10.58%, rgba(255, 255, 255, 0.9) 103.02%, rgba(255, 255, 255, 0.9) 131.05%);
      transform: rotate(-180deg);
      z-index: 99;
    }

    .playlist-item {
      width: 178px;
      margin: 10px 5px 20px;
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 3px 6px rgba(110, 116, 134, 0.24));
      border-radius: 4px;
      background-color: #fff;
      text-decoration: none;

      .playlist-title {
        padding: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #6B6F88;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
      }

      .playlist-image {
        width: 100%;
        height: 104px;
        object-position: center center;
        object-fit: cover;
      }
    }
  }
}
</style>
