<template>
  <section class="features">
    <section class="features-container">
      <section class="illustration-side">
        <div v-for="(feature, index) in features" :key="index" class="illustration-container" :class="{'is-active': feature.active}">
          <img :src="`/img/image-feature${index + 1}.png`" alt="Tailored to your interests" class="illustration">
        </div>
      </section>
      <section class="content-side">
        <section class="features-list">
          <article  v-for="(feature, index) in features" :key="index" class="feature-item" :class="{'is-active': feature.active}">
            <div class="item-number" v-html="index + 1"></div>
            <section class="item-content">
              <p class="item-heading" v-html="feature.heading" @click="setIndex(index)" />
              <p class="item-text" v-html="feature.text" />
            </section>
          </article>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          active: true,
          heading: 'Tailored to your interests',
          text:
            'Pick a topic, we curate the best clips from thousands of podcasts to feed your interests.'
        },
        {
          active: false,
          heading: 'Discover new content',
          text:
            'Don’t lose time searching for podcast you like, we do it for you. Focus on learning on-the-go!'
        },
        {
          active: false,
          heading: 'Dig deeper and learn more',
          text:
            'Switch to “full-episode mode” and listen and subscribe to the full podcasts you like'
        },
        {
          active: false,
          heading: 'Share the knowledge',
          text:
            'Send your favorite clips, episodes or playlists to your friends.'
        }
      ],
      interval: false
    }
  },
  mounted() {
    this.setLocalInterval()
  },
  methods: {
    setLocalInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }

      this.interval = setInterval(() => {
        const currentIndex = this.features.indexOf(this.features.find(el => el.active))
        const nextIndex = currentIndex + 1 === 4 ? 0 : currentIndex + 1
        this.setIndex(nextIndex)
      }, 5000)
    },
    setIndex(nextIndex) {
      const currentIndex = this.features.indexOf(this.features.find(el => el.active))

      this.features[currentIndex].active = false
      this.features[nextIndex].active = true

      this.setLocalInterval()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.features {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 60px 190px;

  @media (max-width: 850px) {
    padding: 64px 30px;
  }

  .features-container {
    width: 100%;
    max-width: 1020px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 850px) {
      flex-direction: column-reverse;
    }

    .illustration-side {
      position: relative;
      width: calc(50% - 60px);
      max-width: 424px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('/img/background-features.svg') no-repeat center center / contain;

      @media (max-width: 850px) {
        width: 100%;
        max-width: 312px;
      }

      &:before {
        content: '';
        width: 100%;
        padding-top: 100%;
      }

      .illustration-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 48px));
        display: flex;
        width: 100%;
        justify-content: center;
        opacity: 0;

        @media (max-width: 850px) {
          height: 330px;
        }

        &.is-active {
          transition: opacity ease-out .6s, transform ease-out .6s;
          opacity: 1;
          transform: translate(-50%, -50%);
        }

        .illustration {
          width: calc(min(20vw, 225px));
          filter: drop-shadow(7.03125px 10.5469px 21.0938px rgba(41, 41, 42, 0.5));

          @media (max-width: 850px) {
            width: auto;
            height: 100%;
          }
        }
      }

      .illustration {
        width: 53%;
        filter: drop-shadow(7.03125px 10.5469px 21.0938px rgba(41, 41, 42, 0.5));
      }
    }

    .content-side {
      width: calc(50% - 60px);
      max-width: 424px;
      display: flex;

      @media (max-width: 850px) {
        width: 100%;
        margin-bottom: 90px;
      }

      .features-list {
        width: 100%;
        display: flex;
        flex-direction: column;

        .feature-item {
          width: 100%;
          display: flex;
          margin-bottom: 24px;

          &.is-active {
            .item-number::before {
              width: 46px;
              height: 46px;
              border-radius: 46px;
              opacity: 1;
            }

            .item-content {
              .item-heading {
                color: #3C476A;
              }

              .item-text {
                height: auto;
                opacity: 1;
                transform: translateY(0);
                transition: opacity ease-out .4s, transform ease-out .4s;
                pointer-events: auto;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          .item-number {
            position: relative;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background: #4FA595;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 40px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.54px;
            color: #FFFFFF;
            margin: 0 40px 0 0;

            @media (max-width: 850px) {
              margin-right: 22px;
            }

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 40px;
              height: 40px;
              border-radius: 40px;
              border: 3px solid #4FA595;
              opacity: 0;
              transition: opacity ease-out .4s, width ease-out .4s, height ease-out .4s, border-radius ease-out .4s;
              will-change: opacity, width, height, border-radius;
            }
          }

          .item-content {
            flex: 1;
            max-width: 303px;
            display: flex;
            flex-direction: column;

            .item-heading {
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 26px;
              color: #CECECE;
              margin: 8px 0 0;
              cursor: pointer;
            }

            .item-text {
              height: 0;
              opacity: 0;
              transform: translateY(10px);
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 140%;
              color: #3C476A;
              margin: 10px 0 0;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
</style>
