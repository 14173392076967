import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyBJxfRdt54omyyCz0e1w2L_tqU4bmjuOvA',
  authDomain: 'mindbites-8ae62.firebaseapp.com',
  databaseURL: 'https://mindbites-8ae62.firebaseio.com',
  projectId: 'mindbites-8ae62',
  storageBucket: 'mindbites-8ae62.appspot.com',
  messagingSenderId: '641731483331',
  appId: '1:641731483331:web:1a913d9d1df8d5a718b476',
  measurementId: 'G-SPNLTFW71F'
}

firebase.initializeApp(config)
